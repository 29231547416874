@import "../Shared/Partials/variables";
/********* Carousel *******/
.home-carousel {
    // margin-top: 60px;
    // @media (min-width:$screen-md) {
    //     margin-top: 100px;
    // }
    .carousel-item {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        height: 500px;
        position: relative;
        @media (min-width: $screen-sm) {
            height: 700px;
        }
        @media (min-width: $screen-md) {
            height: 800px;
        }
        @media (min-width: $screen-lg) {
            height: 930px;
        }
        .carousel-title {
            .c-title {
                color: $color-white;
                font-family: $font-SF-Semibold; 
                margin-bottom: 20px;
                font-size: $font-35;
                letter-spacing: 0.5px;
                line-height: 43px;
                @media (min-width: $screen-md) {
                    font-size: $font-70;
                    line-height: 77px;
                }
                @media (min-width: $screen-lg) {
                    font-size: $font-80;
                    line-height: 85px;
                }   
            }
            left: 15.9%;
            position: absolute;
            top: 41.2%;
            @media (min-width: $screen-md) {
                // font-size: $font-80;
            }
            @media (min-width: $screen-lg) {
                left: 15.8%
            }
            .btn {
                line-height: 40px;
                height: 40px;
                width: 160px;
                @media (min-width: $screen-md) {
                    line-height: 60px;
                    height: 60px;
                    width: 233px;
                }
            }
        }
        
    }
    .slick-prev {
        left: 20px;
        z-index: 1;
    }
    .slick-next {
        right: 20px;
        z-index: 1;
    }
    .slick-prev::before {
        content: "\e605";
        font-family: 'simple-line-icons';
        color: $color-white;
        // display: none;
        font-size: 20px;
        @media (min-width: $screen-sm) {
            font-size: 40px;            
        }
    }
    .slick-next::before {
        content: "\e606";
        font-family: 'simple-line-icons';
        color: $color-white;
        // display: none;
        font-size: 20px;
        @media (min-width: $screen-sm) {
            font-size: 40px;
        }
    }
    .slick-dots {
        bottom: 20px;
    }
    .slick-dots li.slick-active button:before {
        color: $color-white;
    }
}

/**********About Section**********/
.home-about {
    max-width: $max-width-xs;
    margin: auto;
    height: 666px;
    position: relative;
    display: block;
    @media (min-width: $screen-sm) {
        display: flex;
        align-items: center;
        max-width: $max-width-sm;
        height: 440px;
    }
    @media (min-width: $screen-md) {
        max-width: $max-width-md;
        height: 620px;
    }
    @media (min-width: $screen-lg) {
        height: 777px;
        max-width: 1300px;
    }
    img.about-bg {
        position: absolute;
        left: 0;
        top: 59%;
        width: 100%;
        @media (min-width: $screen-sm) {
            left: 38.4%;
            top: 15.7%;
            width: 59%;
        }
    }
    img.about-ip {
        position: absolute;
        left: 42.9%;
        top: 61%;
        z-index: 3;
        width: 56.3%;
        @media (min-width: $screen-sm) {
            left: 62.9%;
            top: 23.4%;
            width: 30.3%;
        }
    }
    h2 {
        text-align: center;
        padding-top: 60px;
        @media (min-width: $screen-sm) {
            padding-top: 0;
            text-align: initial;
        }
        color: $color-secondary;
        padding-bottom: 28px;
    }
    .text-editor-about {
        max-width: auto;
        text-align: center;       
        @media (min-width: $screen-sm) {
            max-width: 270px;
            text-align: left;
        }
        @media (min-width: $screen-md) {
            max-width: 405px;
        }
    }
    p {
        padding-bottom: 7px;
        line-height: 28px; 
    }
}

/**********Stats Section**********/
.home-stats-bg {
    background: #F2EDF8; 
}
.home-stats {
    background: #F2EDF8;     
    padding: 90px 0 40px;
    max-width: $max-width-xs;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
    @media (min-width: $screen-sm) {      
        max-width: $max-width-sm;
        padding: 80px 50px 30px;
    }
    @media (min-width: $screen-md) {
        max-width: $max-width-md;
        padding: 120px 0;
    }
    @media (min-width: $screen-lg) {
        max-width: $max-width-lg;
        padding: 150px 0;
    }
    .item-stats {
        text-align: center;
        width: 100%;
        padding-bottom: 50px;
        @media (min-width: $screen-sm) {
            width: 50%;
        }
        @media (min-width: $screen-md) {
            width: 25%;
            padding-bottom: 0;
        }
    }
    .stats-item {
        color: #481C6E;
    }
    .text-tast {
        color: #8D64A9;
        font-family: $font-SF-Medium;
    }
}
/********Features**********/
.home-features {
    .home-title {
        text-align: center;
        padding-bottom: 30px;
        @media (min-width: $screen-md) {
            padding-bottom: 50px;
        }
        @media (min-width: $screen-lg) {
            padding-bottom: 60px;
        }
    }
    .home-features-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .feature-item {
        width: 100%;
        @media (min-width: $screen-sm) {
            width: 50%;
        }
        @media (min-width: $screen-md) {
            width: 33%;
        }
        a {
            display: inline;
        }
        img {
            width: 100%;
        }
        h5 {
            padding-top: 26px;
            color: $color-secondary;
        }
        p {
            padding-top: 11px;
            height: 63px;
            overflow: hidden;
            line-height: 28px;
        }
        .feature-read {
            color: #c2c2c2;
            padding-top: 14px;
            text-decoration: none;
            text-transform: uppercase;
            a {
                display: inline;    
                &:hover {
                color: $color-primary;
                text-decoration: underline;
                }
            }
        }
    }
}

/***********Profit**************/
.home-profit-bg {
    background: #F2EDF8;
}
.home-profit {
    // max-width: 300px;
    // margin: auto;
    padding: 50px 0;
    @media (min-width: $screen-sm) {
        max-width: $max-width-sm - 100px;
    }
    @media (min-width: $screen-md) {
        padding: 150px 0;
        max-width: $max-width-md;
    }
    @media (min-width: $screen-lg) {
        max-width: $max-width-lg;
    }
    .home-title {
        text-align: center;
    }
    .profit-item {
        text-align: center;
        img {
            width: 98%;
            @media (min-width: $screen-sm) {
                width: 69.9%;
            }
            @media (min-width: $screen-md) {
                width: 48.9%;
            }
            padding-top: 60px;
            margin: auto;
        }
        .profit-name {
            color: $color-secondary;
            padding-top: 30px;
            font-family: "SF-Medium";
        }
        .profit-about {
            margin: auto;
            width: 100%;
            text-align: center;
            line-height: 28px;
            @media (min-width: $screen-xs) {
                width: 42.9%
            }
            @media (min-width: $screen-md) {
                width: 31.9%;
            } 
        }
    }
    .slick-prev::before {
        content: "\e605";
        font-family: 'simple-line-icons';
        color: $color-secondary;
        display: none;
        @media (min-width: $screen-sm) {
            display: block;
        }
    }
    .slick-next::before {
        content: "\e606";
        font-family: 'simple-line-icons';
        color: $color-secondary;
        display: none;
        @media (min-width: $screen-sm) {
            display: block;
        }
    }
    .slick-dots {
        bottom: -60px;
    }


}
/*********Testimonial***********/
.home-testimonial-bg {
    background: #F2EDF8
}
.home-testimonial {
    // margin: auto;
    // max-width: $max-width-xs;
    padding: 50px 0;
    display: flex;
    flex-wrap: wrap;
    // @media (min-width: $screen-sm) {
    //     max-width: $max-width-sm
    // }
    @media (min-width: $screen-md) {
        padding: 130px 0;        
        // max-width: $max-width-md;
    }
    // @media (min-width: $screen-lg) {
    //     max-width: $max-width-lg;
    // }
    .testimonial-title {
        color: $color-secondary;
        width: 100%;
        text-align: center;
        @media (min-width: $screen-md) {
            width: 50%;
            text-align: initial;
        }
    }
    .testimonial-slider {
        width: 100%;
        @media (min-width: $screen-md) {
            width: 50%;
        }
    }
    .testimonial-item {
        position: relative;
        // padding-left: 45px;
        @media (min-width: $screen-md) {
            padding-left: 45px;
        }
        img.quote-img {
            position: absolute;
            top: 0;
            left: 42%;
            z-index: 2;
            @media (min-width: $screen-md) {
                left: 0;
            }
        }
        .comment-testimonial {
            color: #484848;
            font-family: $font-SF-Thin;
            // font-size: $font-16;
            // line-height: 30px;
            position: relative;
            // width: 80.8%;
            width: 100%;
            z-index: 3;
            padding: 20px 0;
            text-align: center;
            @media (min-width: $screen-md) {
                text-align: left;
            }
        }
        .people-testimonial {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            @media (min-width: $screen-md) {
                justify-content: initial;
            }
            .avatar {
                max-width: 60px;
                width: 100%;
                display: block;
                @media (min-width: $screen-md) {
                    width: 11.3%;                
                }
            }
            .info-testimonial {
                padding: 10px 15px;
                width: 100%;
                text-align: center;
                @media (min-width: $screen-md) {
                    text-align: left;
                    width: auto;
                }
            }
            .name {
                color: $color-secondary;
                font-family: $font-SF-Semibold;
                font-size: $font-14;
                line-height: 20px;
                text-transform: uppercase;
            }
            .status {
                color: #777777;
                font-family: $font-SF-Text-Regular;
                font-size: $font-14;
                font-style: italic;
                line-height: 24px;
            }
        }
    }
    .slick-dots {
        bottom: -40px;        
        text-align: center;
        // padding-left: 45px;
        @media (min-width: $screen-md) {
            bottom: -70px;
            text-align: left;
            padding-left: 45px;    
        }
    }
}
/************Instruction**********/
.home-instruction {
    margin: auto;
    max-width: $max-width-xs;
    padding: 50px 0;
    @media (min-width: $screen-sm) {
        max-width: $max-width-sm;
    }
    @media (min-width: $screen-md) {
        padding: 130px 0;
        max-width: $max-width-md;
    }
    @media (min-width: $screen-lg) {
        max-width: $max-width-lg;
    }
    .instruction-title {
        color: $color-secondary;
        text-align: center;
        margin-bottom: 5.7%;
    }
    .instruction-content {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        justify-content: space-between;
        .ins-bg {
            width: 100%;
            @media (min-width: $screen-sm) {
                width: 66%;                
            }
            position: relative;
            img {
                width: 100%
            }
            .ip-step {
                position: absolute;
                height: 63.6%;
                top: 18%;
                left: 28.4%;
                img {
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
        .ins-steps {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-content: space-evenly;
            order: -1;
            text-align: center;
            @media (min-width: $screen-sm) {
                width: 33%;
                padding-bottom: 10%;
                order: 1;
                text-align: left;
            }
            @media (min-width: $screen-md) {
                width: 24%;
            }
            .step {
                width: 19%;
                @media (min-width: $screen-sm) {
                    width: 100%;
                }
                span:hover {
                    .dots{
                        background: $color-primary; 
                    }
                    .step-text {
                        color: $color-black;
                    }
                }
                &:nth-last-child(2) .dots::before {
                    display: none;
                    @media (min-width: $screen-sm) {
                        display: block;
                    }
                }
            }
            .step-text-mobile {
                display: block;
                width: 100%;
                margin-top: 10px;
                @media (min-width: $screen-sm) {
                    display: none;
                }
            }
        }
    }
    .dots {
        color: $color-white;
        cursor: pointer;
        display: inline-block;
        font-size: $font-10;
        font-family: $font-SF-Display-Regular;
        background: linear-gradient(316deg, #AB47BC 4%, #8E24AA 49%, #5D2E86 100%);
        padding: 9px 13px;
        border-radius: 50%;
        position: relative;
        width: 32px;
        @media (min-width: $screen-sm) {
            font-size: $font-14;
            padding: 12px 17px;
            width: 40px;    
        }
        &:hover, &.active {
            background: linear-gradient(315deg, #FCBC64 0%, #FF9800 35%, #F58220 100%);
        }
        &::before {
            content:"";
            position: absolute;
            left: 0;
            top: 50%;
            height: 1px;
            width: 70px;
            z-index: -1;
            background: $color-E6;
            @media (min-width: $screen-sm) {
                left: 50%;
                top: -100%;
                height: 120px;
                width: 1px;
                // left: 0;
            }
        }
    }
    .step-text {
        display: inline-block;
        color: $color-99;
        padding-left: 5px;
        text-transform: uppercase;
        font-size: 12px;
        padding-top: 8px;
        line-height: 18px;
        cursor: pointer;
        display: none;
        @media (min-width: $screen-sm) {
            display: inline-block;
            font-size: $font-14;
            padding-left: 20px;
            padding-top: 0;
            line-height: 28px;
        }
        &:hover, &.active {
            color: $color-black;
        }
    }
}

///////////Xài chung/////////////
.home-title {
    font-size: $font-30;
    color: $color-secondary;
    font-family: $font-SF-Semibold;
    line-height: 35px;
    @media (min-width: $screen-md) {
        font-size: $font-50;
        line-height: 55px;
    }
}

.home-text {
    color: $color-7E;
    font-family: $font-SF-Medium;
    font-size: $font-10;
    line-height: 20px;
    @media (min-width: $screen-xs) {
        font-size: $font-14;
        line-height: 28px;
    }
    @media (min-width: $screen-md) {
        font-size: $font-16;    
    }
}
.home-mini-title {
    color: $color-secondary;
    font-family: $font-SF-Semibold;    
    font-size: $font-24;
    letter-spacing: 0.19px;
    line-height: 30px;
}
.slick-dots {
    li {
        margin: 0;
        &.slick-active button:before {
            color: $color-secondary;
            font-size: $font-12;    
        }
    }
}